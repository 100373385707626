<template>
    <div class="page historia" data-page="historial">
       <div class="cabecera">
            <h1>Historial</h1>   
       </div>
       <div class="view">
           
       </div>
    </div>
</template>

<script>

export default {
    name: 'Historial',
    props: [],
    components: {},
    data() {
        return {
        }
    },
    created() {
    }
}
</script>
<style scoped lang="scss">
    
</style>
